CKEDITOR_BASEPATH = '/editor/';
contentsCss : 'http://www.MySite.org/css/frontend.css',

document.addEventListener('DOMContentLoaded', function() {
    if ($('.p-editable').length) {
        $('body').append("<style>.cke_button__psave_label { display: inline; }</style>");

CKEDITOR.config.contentsCss = '/css/frontend.css';
CKEDITOR.config.allowedContent = true;
CKEDITOR.config.extraAllowedContent = '*(*);*{*}';
CKEDITOR.config.extraAllowedContent = 'span;ul;li;table;td;style;*[id];*(*);*{*}';
CKEDITOR.stylesSet.add( 'new', [


    { name: 'Italic Title',     element: 'h2', styles: { 'font-style': 'italic' } },
    { name: 'Subtitle',         element: 'h3', styles: { 'color': '#aaa', 'font-style': 'italic' } },
    {
        name: 'Special Container',
        element: 'div',
        styles: {
            padding: '5px 10px',
            background: '#eee',
            border: '1px solid #ccc'
        }
    },

    { name: 'Marker',           element: 'span', attributes: { 'class': 'marker' } },
    { name: 'Quotes',           element: 'div', attributes: { 'class': 'quotes orange' } },
    { name: 'Big',              element: 'big' },
    { name: 'Small',            element: 'small' },

    { name: 'Computer Code',    element: 'code' },
    { name: 'Keyboard Phrase',  element: 'kbd' },
    { name: 'Sample Text',      element: 'samp' },
    { name: 'Variable',         element: 'var' },

    { name: 'Deleted Text',     element: 'del' },
    { name: 'Inserted Text',    element: 'ins' },

    { name: 'Cited Work',       element: 'cite' },
    { name: 'Inline Quotation', element: 'q' },

    { name: 'Language: RTL',    element: 'span', attributes: { 'dir': 'rtl' } },
    { name: 'Language: LTR',    element: 'span', attributes: { 'dir': 'ltr' } },

    /* Object Styles */

    {
        name: 'Styled image (left)',
        element: 'img',
        attributes: { 'class': 'left' }
    },

    {
        name: 'Styled image (right)',
        element: 'img',
        attributes: { 'class': 'right' }
    },

    {
        name: 'Compact table',
        element: 'table',
        attributes: {
            cellpadding: '5',
            cellspacing: '0',
            border: '1',
            bordercolor: '#ccc'
        },
        styles: {
            'border-collapse': 'collapse'
        }
    },

    { name: 'Borderless Table',     element: 'table',   styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
    { name: 'Square Bulleted List', element: 'ul',      styles: { 'list-style-type': 'square' } }
] );

        $('.navbar').after('<div id="p-editor-alert" role="alert" class="alert alert-success alert-dismissible" style="display: none"></div>');

        CKEDITOR.disableAutoInline = false;
       
        $('.p-editable').each(function() {
            CKEDITOR.inline(this, {
                extraPlugins: 'sharedspace,pSave,sourcedialog',
                removePlugins: 'maximize,resize',
                sharedSpaces: {
                    top: 'p-editor-toolbar',
                    bottom: 'p-editor-path'
                },
                toolbarGroups: [{
                    name: 'save',
                    groups: ['pSave']
                }, {
                    name: 'basicstyles',
                    groups: ['basicstyles', 'cleanup']
                }, {
                    name: 'styles',
                    groups: ['styles']
                }, {
                    name: 'paragraph',
                    groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
                }, {
                    name: 'insert',
                    groups: ['insert']
                }, {
                    name: 'links',
                    groups: ['links']
                }, {
                    name: 'editing',
                    groups: ['find', 'selection', 'spellchecker', 'editing']
                }, {
                    name: 'clipboard',
                    groups: ['clipboard', 'undo']
                }, {
                    name: 'forms',
                    groups: ['forms']
                }, {
                    name: 'source',
                    groups: ['sourcedialog']
                }, {
                    name: 'tools',
                    groups: ['tools']
                }, {
                    name: 'document',
                    groups: ['mode', 'document', 'doctools']
                }, {
                    name: 'others',
                    groups: ['others']
                }, {
                    name: 'colors',
                    groups: ['colors']
                }, {
                    name: 'about',
                    groups: ['about']
                }, {
                    name: 'about',
                    groups: ['about']
                }],
                removeButtons: 'Maximize,About,Subscript',
            filebrowserImageBrowseUrl: '/admin/assets?type=Images&_token=' + $('meta[name="csrf-token"]').attr('content'),
            filebrowserImageUploadUrl: '/admin/assets/upload?type=Images&_token=' + $('meta[name="csrf-token"]').attr('content'),
            filebrowserBrowseUrl: '/admin/assets?type=Files&_token=' + $('meta[name="csrf-token"]').attr('content'),
            filebrowserUploadUrl: '/admin/assets/upload?type=Files&_token=' + $('meta[name="csrf-token"]').attr('content')
            });
        });
    }

   
});
